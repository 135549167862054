<template>
  <div>
    <div>
      <user-activity-session
        v-if="material && material.id"
        class="mb-2"
        card
        :user-activity-session="userActivitySession"
      />

      <div class="card mb-2">
        <div class="card-header py-lg-0 border-bottom-0 rounded">
          <div class="row">
            <div class="col-12 col-md">
              <be-nav tabs>
                <be-nav-item
                  v-if="isAgenda && !material.external_uploaded_at"
                  :active="currentPage == 'template-list'"
                  @click="setPage('template-list')"
                >
                  {{ $t("activerecord.models.agenda_template.other") }}
                </be-nav-item>

                <be-nav-item
                  v-if="isMinutes"
                  :active="currentPage == 'attendances'"
                  @click="setPage('attendances')"
                >
                  <i
                    v-if="hasLoadedAttendances && hasAttendanceWarnings"
                    class="fa-duotone fa-triangle-exclamation fa-fw text-warning"
                  />
                  {{ $t("components.meetings.material.attendances.title") }}
                  <be-badge variant="light">{{ attendances.length }}</be-badge>
                </be-nav-item>

                <be-nav-item
                  :active="currentPage == 'editor'"
                  @click="setPage('editor')"
                >
                  <i
                    v-if="formInvalid && currentPage != 'editor'"
                    class="fas fa-hexagon-exclamation fa-fw text-danger"
                  />
                  {{ itemTabTitle }}
                </be-nav-item>

                <be-nav-item
                  v-if="isMinutes"
                  :active="currentPage == 'attachments'"
                  @click="setPage('attachments')"
                >
                  {{ $t("components.meetings.material.attachments.title") }}
                  <be-badge variant="light">{{ attachments.length }}</be-badge>
                </be-nav-item>

                <be-nav-item
                  :active="currentPage == 'preview'"
                  @click="setPage('preview')"
                >
                  {{ $t("buttons.titles.preview") }}
                </be-nav-item>
              </be-nav>
            </div>

            <div
              class="col-12 col-md-auto d-flex align-items-center mt-2 mt-md-0"
            >
              <be-dropdown ellipsis>
                <be-dropdown-item
                  v-if="canShow"
                  :href="url(`/meetings/${meeting.id}`)"
                >
                  {{ $t("models.meeting.show_meeting") }}
                </be-dropdown-item>

                <agenda-template-dropdown-item
                  v-if="meeting.id && !material.external_uploaded_at"
                  :meeting-id="meeting.id"
                  :material-type="materialType"
                />

                <be-dropdown-item v-be-modal.material-upload>
                  {{
                    materialType === "agenda"
                      ? $t(
                          "components.meetings.material.upload_modal.upload_agenda"
                        )
                      : $t(
                          "components.meetings.material.upload_modal.upload_minutes"
                        )
                  }}
                </be-dropdown-item>

                <template v-if="canShow && !material.external_uploaded_at">
                  <be-dropdown-divider />

                  <be-dropdown-item v-be-modal.material-pdf>
                    {{ exportTitle }}
                  </be-dropdown-item>
                </template>

                <be-dropdown-item
                  v-if="
                    $currentCompany.continuous_numbering &&
                    !material.external_uploaded_at
                  "
                  v-be-modal.change-item-start-number
                >
                  {{
                    $t(
                      "components.meetings.material.editor.change_item_start_number"
                    )
                  }}
                </be-dropdown-item>

                <be-dropdown-divider
                  v-if="canDestroy && (meeting.id || (isMinutes && canShow))"
                />

                <be-dropdown-item
                  v-if="canDestroy"
                  variant="danger"
                  @click="confirmDestroy"
                >
                  {{ $t("buttons.titles.remove") }}
                </be-dropdown-item>
              </be-dropdown>

              <form
                v-show="false"
                ref="destroyMaterialForm"
                :action="
                  isMinutes
                    ? `${meeting.paths.base}/minutes`
                    : `${meeting.paths.base}/agenda`
                "
                method="post"
              >
                <input type="hidden" name="_method" value="DELETE" />

                <input
                  type="hidden"
                  name="authenticity_token"
                  :value="$csrfToken"
                />
              </form>
            </div>
          </div>
        </div>
      </div>

      <template-list
        v-if="currentPage === 'template-list'"
        :has-meeting-items="items.length > 0"
        :templates="templates"
        @template-selected="selectTemplate"
        @update-page="setPage"
      />

      <attendance-table
        v-show="isMinutes && currentPage === 'attendances'"
        @update-page="setPage('editor')"
      />

      <material-items
        v-show="currentPage === 'editor'"
        @switch-to-template-list="setPage('template-list')"
      />

      <material-attachments v-if="currentPage === 'attachments'" />

      <div v-show="currentPage === 'preview'" class="card mb-2 pt-4">
        <external-material-show
          v-if="material.external_uploaded_at && items[0]"
          :material="material"
          :item="items[0]"
          :attachments="attachments"
          :meeting="meeting"
        />

        <material-display
          v-else
          :with-signature-lines="!hasActiveFeature('e-signature')"
        />

        <material-editor-footer current-page="preview" />
      </div>
    </div>

    <material-pdf-modal
      :meeting="meeting"
      :material="material"
      :data-last-updated-at="lastUpdatedAt"
    />

    <material-upload-modal
      modal-id="material-upload"
      :material="material"
      :has-items="items.length > 0"
      @material-updated="materialUploaded"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";

import materialType from "./materialState";

import AttendanceTable from "./AttendanceTable.vue";
import MaterialAttachments from "./MaterialAttachments.vue";
import MaterialItems from "./MaterialItems.vue";
import MaterialDisplay from "./MaterialDisplay.vue";
import TemplateList from "./TemplateList.vue";
import MaterialUploadModal from "./MaterialUploadModal.vue";
import MaterialEditorFooter from "./MaterialEditorFooter.vue";
import ExternalMaterialShow from "./ExternalMaterialShow.vue";

import UserActivitySession from "@/components/shared/UserActivitySession.vue";

export default {
  components: {
    AttendanceTable,
    ExternalMaterialShow,
    MaterialAttachments,
    MaterialDisplay,
    MaterialEditorFooter,
    MaterialItems,
    MaterialUploadModal,
    TemplateList,
    UserActivitySession,
  },

  mixins: [materialType],

  props: {
    initialMeeting: {
      type: Object,
      required: true,
    },

    initialMaterial: {
      type: Object,
      required: true,
    },

    userActivitySession: {
      type: Object,
      required: true,
      default: () => null,
    },

    templates: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      currentPage: null,
      sendReview: false, // if comments modal should be in review mode
      saveState: "saved",
    };
  },

  computed: {
    ...mapGetters("material", [
      "formInvalid",
      "isDirty",
      "items",
      "loading",
      "material",
      "persistedItems",
      "lastUpdatedAt",
    ]),

    ...mapGetters({
      meeting: "material/meeting",
      attachments: "material/attachments",
      attendances: "attendances/attendances",
      attendancesWithUsers: "attendances/attendancesWithUsers",
      hasLoadedAttendances: "attendances/hasLoaded",
      hasAttendanceWarnings: "attendances/hasWarnings",
      hasActiveFeature: "company/hasActiveFeature",
      hasLoadedProjects: "company/hasLoadedProjects",
    }),

    canDestroy() {
      return this.material.id && this.material.policy?.destroy;
    },

    canShow() {
      return this.meeting.policy?.show;
    },

    itemTabTitle() {
      if (this.isAgenda) {
        return this.$t("activerecord.models.agenda.one");
      }

      return this.$t("activerecord.models.minutes.one");
    },

    minutesReviewStarted() {
      return this.meeting.process.active_state === "review";
    },

    exportTitle() {
      if (this.isAgenda) {
        return this.$t("buttons.titles.export_w_title", {
          title: this.$t("activerecord.models.agenda.one").toLowerCase(),
        });
      }

      return this.$t("buttons.titles.export_w_title", {
        title: this.$t("activerecord.models.minutes.one").toLowerCase(),
      });
    },
  },

  watch: {
    loading(value) {
      if (value) {
        this.saveState = "saving";
      } else {
        if (this.formInvalid) {
          this.saveState = "error";
        } else {
          this.saveState = "saved";
        }
      }
    },

    formInvalid(invalid) {
      if (invalid) {
        this.saveState = "error";
      }
    },

    isDirty(value) {
      if (value) {
        this.saveState = "edited";
      }
    },

    // When a non-admin user changes the secretary for the meeting to another attendance,
    // they revoke their access for editing the meeting and we therefore reload the page.
    attendances: {
      handler(newAttendances) {
        if (this.platformAdmin) {
          return;
        }

        newAttendances.forEach((attendance) => {
          if (
            attendance.user_id === this.$currentUser.id &&
            !attendance.secretary
          ) {
            window.location = this.meeting.paths.base;
          }
        });
      },

      deep: true,
    },
  },

  async created() {
    this.loadMeeting(this.initialMeeting);
    this.loadItemsAndMaterial(this.initialMaterial);
    this.fetchPhrases({ company: this.$currentCompany });

    if (this.initialMaterial.material_type === "minutes") {
      this.fetchAttendances({ meeting: this.meeting, attendanceTable: true });
      this.fetchAttachments();
    }

    // Load Projects
    if (
      this.hasActiveFeature({ name: "projects" }) &&
      !this.hasLoadedProjects
    ) {
      this.loadProjects();
    }

    this.fetchReferences(this.initialMaterial.items);

    if (this.initialMaterial.material_type === "minutes") {
      // Wait for the attendances to be loaded before setting the initial page
      const unwatchHasLoadedAttendances = this.$watch(
        "hasLoadedAttendances",
        (value) => {
          if (value) {
            this.setInitialPage();
            unwatchHasLoadedAttendances();
          }
        }
      );
    } else {
      this.setInitialPage();
    }
  },

  methods: {
    ...mapActions({
      fetchAttendances: "attendances/fetchAttendances",
    }),

    ...mapActions("material", [
      "createItem",
      "fetchAttachments",
      "fetchFollowUpReferences",
      "removeItem",
      "setEditorType",
      "update",
      "useTemplate",
    ]),

    ...mapMutations("material", [
      "loadItemsAndMaterial",
      "loadMeeting",
      "addErrorsToEmptyTasksDecisions",
    ]),

    ...mapActions("company", ["loadProjects"]),
    ...mapActions("phrases", ["fetchPhrases"]),

    /**
     * Page management
     */
    setInitialPage() {
      if (this.isMinutes) {
        if (
          this.material.created_at === this.lastUpdatedAt ||
          this.hasAttendanceWarnings
        ) {
          this.setPage("attendances");
        } else {
          this.setPage("editor");
        }
      } else {
        if (this.persistedItems.length > 0) {
          this.setPage("editor");
        } else {
          this.setPage("template-list");
        }
      }
    },

    setPage(page) {
      this.currentPage = page;
      // Scroll to the top of the page
      window.scrollTo(0, 0);
    },

    /**
     * Template management
     */
    async selectTemplate(template) {
      await this.useTemplate(template);
      this.setPage("editor");
    },

    async confirmDestroy() {
      const isConfirmed = await this.promptRemovalConfirm({
        title: this.$t("nav.confirm_delete_w_item_type_w_title", {
          item_type: this.$t(
            `activerecord.attributes.material.${this.materialType}`
          ).toLowerCase(),

          title: `#${this.meeting.number} - ${this.meeting.title}`,
        }),

        confirmWithInput: this.$t(
          `components.meetings.material.editor.confirm_destroy.format.${this.materialType}`,
          { number: this.meeting.number }
        ),

        stayOpenAfterConfirm: true,
      });

      if (isConfirmed) {
        this.$refs["destroyMaterialForm"].submit();
        return false;
      }
    },

    fetchReferences(items) {
      const followUps = [
        "follow_up_reports",
        "follow_up_decisions",
        "follow_up_meetings",
        "follow_up_tasks",
      ];
      if (items.some((item) => followUps.includes(item.subtitle))) {
        this.fetchFollowUpReferences();
      }
    },

    materialUploaded(material) {
      this.loadItemsAndMaterial(material);
      this.setPage("editor");
    },
  },
};
</script>
