<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <!-- Progress container -->
        <div class="card mb-3">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h3>
                {{
                  $t("components.onboarding.introduction.welcome_with_name", {
                    name: localUser.name,
                  })
                }}
              </h3>

              <!-- Skip onboarding on medium and large devices -->
              <be-link
                v-if="confirmedAt"
                v-be-tooltip="$t('components.onboarding.skip_onboarding_info')"
                class="text-muted align-self-center d-none d-md-inline-block"
                :href="url('/users/onboarding?skip=true')"
                data-method="POST"
              >
                {{ $t("components.onboarding.skip_onboarding") }}
              </be-link>
            </div>

            <p class="mb-2">
              {{
                $t("components.onboarding.introduction.steps_completed", {
                  completedSteps: completedNumberOfSteps,
                  totalSteps: totalNumberOfSteps,
                })
              }}
            </p>

            <be-progress class="mt-2">
              <be-progress-bar
                :value="completedNumberOfSteps"
                :max="totalNumberOfSteps"
              />
            </be-progress>

            <!-- Skip onboarding on small devices -->
            <be-button
              v-if="confirmedAt"
              variant="outline-secondary"
              class="d-md-none"
              :href="url('/users/onboarding?skip=true')"
              data-method="POST"
              block
            >
              {{ $t("components.onboarding.skip_onboarding") }}
            </be-button>
          </div>
        </div>

        <!-- Email confirmation -->
        <div class="card mb-3">
          <div class="card-header">
            <div class="row no-gutters">
              <div class="col-auto d-flex align-items-center">
                <i
                  class="fas fa-circle-check mr-3"
                  :class="emailConfirmationIconClasses"
                />
              </div>

              <div class="col">
                <h3 class="mb-0">
                  {{
                    $t("components.onboarding.introduction.finish_registration")
                  }}
                  <span>*</span>
                </h3>
              </div>
            </div>
          </div>

          <div class="card-body p-0 pb-2">
            <email-confirmation-section
              :user="localUser"
              :confirmed-at="confirmedAt"
            />
          </div>
        </div>

        <!-- About User -->
        <div class="card mb-3">
          <div class="card-header" :class="{ 'border-bottom-0': !confirmedAt }">
            <div class="row no-gutters">
              <div class="col-auto d-flex align-items-center">
                <i
                  class="fas fa-circle-check mr-3"
                  :class="userInfoIconClasses"
                />
              </div>

              <div class="col">
                <h3 class="mb-0">
                  {{ userInfoSectionTitle }}
                </h3>
              </div>
            </div>
          </div>

          <div v-show="confirmedAt" class="card-body p-0">
            <fortnox-user-section
              v-if="isFortnoxNewOnboarding"
              @report-and-analysis-get-started-visited="
                updateProgress(
                  'user_info_fortnox',
                  'report_and_analysis_get_started_visited'
                )
              "
            />

            <user-section
              v-else
              :aws-url="awsUrl"
              :user="localUser"
              @bank-id-activated="
                updateProgress('user_info', 'bank_id_registered')
              "
              @bank-id-skipped="
                updateProgress('user_info', 'bank_id_registered', false, true)
              "
            />
          </div>

          <div
            v-if="confirmedAt"
            class="card-footer d-flex flex-column flex-lg-row justify-content-md-end"
          >
            <be-button
              variant="success"
              class="order-1 order-lg-2"
              :href="url('/users/onboarding')"
              data-method="POST"
            >
              {{ $t("components.onboarding.introduction.get_started") }}!
            </be-button>
          </div>
        </div>

        <!-- Support contact -->
        <template v-if="$platform.theme.theme_name !== 'bank1'">
          <fortnox-support-section
            v-if="isFortnox && isFortnoxNewOnboarding"
            :is-fortnox-new-onboarding="isFortnoxNewOnboarding"
          />

          <support-section v-else :is-fortnox="isFortnox" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EmailConfirmationSection from "@/components/onboarding/user/EmailConfirmationSection.vue";
import UserSection from "@/components/onboarding/user/boardeaser/UserSection.vue";
import FortnoxUserSection from "@/components/onboarding/user/fortnox/UserSection.vue";
import SupportSection from "@/components/onboarding/user/boardeaser/SupportSection.vue";
import FortnoxSupportSection from "@/components/onboarding/user/fortnox/SupportSection.vue";
import omit from "lodash/omit";

export default {
  components: {
    EmailConfirmationSection,
    UserSection,
    FortnoxUserSection,
    SupportSection,
    FortnoxSupportSection,
  },

  props: {
    awsUrl: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      localUser: {},
      slide: null,
    };
  },

  computed: {
    ...mapGetters({
      onboardingProgress: "current_user/getOnboardingProgress",
    }),

    isFortnox() {
      return this.$platform.theme.theme_name === "fortnox";
    },

    isFortnoxNewOnboarding() {
      return this.isFortnox && this.flipperFlag("fortnox_new_user_onboarding");
    },

    themeOnboardingProgress() {
      let onboardingProgress = this.cloneDeep(this.onboardingProgress);

      if (this.isFortnox && this.isFortnoxNewOnboarding) {
        return omit(
          onboardingProgress,
          "user_info",
          "support",
          "support_fortnox"
        );
      }

      if (!this.platformEnabled("onboarding_bankid")) {
        onboardingProgress.user_info = omit(
          onboardingProgress.user_info,
          "bank_id_registered"
        );
      }

      return omit(
        onboardingProgress,
        "user_info_fortnox",
        "support_fortnox",
        "support"
      );
    },

    confirmedAt() {
      return this.localUser.confirmed_at;
    },

    totalNumberOfSteps() {
      return Object.values(this.themeOnboardingProgress).reduce(
        (totalSteps, steps) => {
          return totalSteps + Object.keys(steps).length;
        },
        0
      );
    },

    completedNumberOfSteps() {
      return Object.values(this.themeOnboardingProgress).reduce(
        (totalSteps, steps) => {
          return (
            totalSteps +
            Object.values(steps).filter((stepCompleted) => {
              return stepCompleted == true;
            }).length
          );
        },
        0
      );
    },

    sectionCompleted() {
      return (sectionKey) => {
        return !Object.keys(this.themeOnboardingProgress[sectionKey]).filter(
          (actionKey) => !this.themeOnboardingProgress[sectionKey][actionKey]
        ).length;
      };
    },

    emailConfirmationIconClasses() {
      return this.sectionCompleted("email_confirmation")
        ? "text-success"
        : "text-light";
    },

    userInfoIconClasses() {
      const themeUserInfoId = this.isFortnoxNewOnboarding
        ? "user_info_fortnox"
        : "user_info";

      return this.sectionCompleted(themeUserInfoId)
        ? "text-success"
        : "text-light";
    },

    userInfoSectionTitle() {
      return this.isFortnoxNewOnboarding
        ? this.$t(
            "components.onboarding.introduction.user_section_fortnox.title"
          )
        : this.$t("components.onboarding.introduction.customize_your_account");
    },
  },

  mounted() {
    this.localUser = this.cloneDeep(this.$currentUser);
  },

  methods: {
    async updateProgress(
      sectionType,
      actionKey,
      completed = true,
      skipped = false
    ) {
      try {
        const response = await axios.patch("/users/onboarding/progress", {
          user: {
            section_type: sectionType,
            action_key: actionKey,
            completed: completed,
            skipped: skipped,
          },
        });

        this.updateUser(response.data);
      } catch (error) {
        this.handleError(error);
      }
    },

    async updateUser(updatedUser) {
      this.localUser = updatedUser;
      this.$store.commit(
        "current_user/setUser",
        this.cloneDeep(this.localUser)
      );
    },
  },
};
</script>
