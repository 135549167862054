<template>
  <div
    class="card mb-2"
    :class="{
      show: expanded,
    }"
  >
    <div class="card-header px-4" @click="toggleExpanded">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-md">
          <div class="text-reset text-decoration-none">
            <div class="d-flex align-items-center">
              <i
                class="fas mr-3 text-primary"
                :class="`fa-chevron-${expanded ? 'down' : 'right'}`"
              />

              <div class="h5 text-left text-reset-line-height mb-0">
                <div class="d-block">
                  {{
                    $t("companies.dashboard.header.meeting_header", {
                      number: meeting.number,
                      title: meeting.title,
                    })
                  }}
                </div>

                <be-link
                  class="mt-1 text-muted"
                  :href="`/companies/${company.nanoid}`"
                  @click.stop
                >
                  {{ company.title }}
                </be-link>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!expanded"
          class="d-md-flex font-weight-normal font-size-base"
        >
          <template v-if="meeting.start_at">
            <div class="mt-3 mt-md-1 mt-xl-0 ml-4 ml-md-1 col-12 col-md-auto">
              <i class="fal fa-calendar fa-fw text-primary mr-1" />
              {{ $d(new Date(meeting.start_at), "short") }}
            </div>

            <div class="mt-2 mt-md-1 mt-xl-0 ml-4 ml-md-1 col-12 col-md-auto">
              <i class="fal fa-clock fa-fw text-primary mr-1" />
              {{ $d(new Date(meeting.start_at), "time") }}
              -
              {{ $d(new Date(meeting.end_at), "time") }}
            </div>
          </template>

          <template v-if="showMeetingSeries">
            <div class="mt-2 mt-md-1 mt-xl-0 ml-4 ml-md-1 col-12 col-md-auto">
              <i class="fal fa-fw fa-screen-users text-primary mr-1" />
              {{
                $t(
                  `models.meeting.meeting_series_type.${meeting.meeting_series_type}.one`
                )
              }}
            </div>
          </template>
        </div>

        <div v-else class="col-12 col-md-auto mt-3 mt-md-0">
          <be-button
            v-if="showMeetingOverview"
            variant="outline-primary"
            :href="
              url(`/meetings/${meeting.id}/overview`, { company: company })
            "
            @click.stop
          >
            {{ $t("views.companies.meetings.overviews.show.title") }}
          </be-button>

          <be-button
            variant="outline-secondary"
            :href="meeting.paths.base"
            @click.stop
          >
            {{ $t("models.meeting.show_meeting") }}
          </be-button>
        </div>
      </div>
    </div>

    <div v-show="expanded" class="card-body">
      <time-and-location
        :meeting="meeting"
        :inline-calendar-link="true"
        :disable-buttons="true"
        :with-suggestions="withSuggestions"
      />

      <template v-if="withDocuments">
        <h5
          v-if="loading || currentFolder"
          v-text="
            $t('components.meetings.tabs.components.meeting_documents.title')
          "
        />

        <be-skeleton-table
          v-if="loading"
          :rows="3"
          :columns="3"
          :table-props="{ striped: true }"
        />

        <meeting-documents
          v-else-if="currentFolder"
          :meeting="meeting"
          :folder="currentFolder"
          :hide-header="true"
        />
      </template>
    </div>
  </div>
</template>

<script>
import TimeAndLocation from "@/components/meetings/tabs/TimeAndLocationTab.vue";
import MeetingDocuments from "@/components/meetings/tabs/components/MeetingDocuments.vue";

export default {
  components: {
    TimeAndLocation,
    MeetingDocuments,
  },

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    expanded: {
      type: Boolean,
      required: false,
      default: false,
    },

    keyPrefix: {
      type: String,
      required: false,
      default: "meeting",
    },

    withDocuments: {
      type: Boolean,
      required: false,
      default: true,
    },

    withSuggestions: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: ["toggle-expanded"],

  data() {
    return {
      currentFolder: null,
      loading: false,
    };
  },

  computed: {
    company() {
      return this.$store.getters["company/getById"](this.meeting.company_id);
    },

    showMeetingOverview() {
      return this.meeting.policy?.show_overview && this.meeting.agenda?.id;
    },

    showMeetingSeries() {
      return (
        this.flipperFlag("meeting_series_flipper") &&
        this.$platform.features.meeting_series
      );
    },
  },

  watch: {
    expanded(val) {
      if (this.withDocuments && val === true) {
        this.getFolder(this.meeting);
      }
    },
  },

  methods: {
    async getFolder(meeting) {
      if (
        meeting &&
        meeting.policy?.show_overview && // If you cannot see overview, you cannot get the meeting material
        meeting.folder_id &&
        !this.currentFolder &&
        !this.loading
      ) {
        this.loading = true;

        try {
          const { data } = await axios.get(`${meeting.paths.base}/folder`);

          this.currentFolder = data;
        } catch (error) {
          this.handleError(error);
        } finally {
          this.loading = false;
        }
      }
    },

    toggleExpanded() {
      this.$emit("toggle-expanded");
    },
  },
};
</script>
