<template>
  <header>
    <h1>
      {{ $t("views.companies.inquiry_payments.index.payments") }}
    </h1>
  </header>

  <be-alert v-if="createdInquiryPaymentsCount" variant="info">
    <i18n-t
      keypath="views.companies.inquiry_payments.index.unapproved_payments_in_pe_accounting"
    >
      <template #link>
        <be-link :href="peAccountingUrl">
          {{ $t("views.companies.inquiry_payments.index.go_to_pe_accounting") }}
        </be-link>
      </template>
    </i18n-t>
  </be-alert>

  <be-modal
    id="handle-payment-modal"
    :title="handlePaymentModalTitle"
    @hidden="resetNewInquiryForm"
  >
    <be-form-group
      v-if="!form.id"
      :label="$t('views.companies.inquiry_payments.index.choose_inquiry')"
      label-for="inquiry"
      required
    >
      <be-form-select
        id="inquiry"
        v-model="form.inquiry.inquiry_id"
        :options="payableInquiries"
        required
      />
    </be-form-group>

    <form-new skip-checkbox single-payment />

    <template #footer="{ cancel }">
      <!-- Delete button -->
      <be-button @click="cancel">
        {{ $t("buttons.titles.cancel") }}
      </be-button>

      <be-button v-if="!form.id" variant="primary" @click="handleMakePayment">
        {{
          $t(
            "views.companies.inquiry_payments.index.create_payment_and_send_to_pe_accounting"
          )
        }}
      </be-button>

      <be-button v-if="form.id" variant="primary" @click="handleUpdatePayment">
        {{ $t("views.companies.inquiry_payments.index.update_payment") }}
      </be-button>
    </template>
  </be-modal>

  <be-modal
    id="inquiry-payment-modal"
    :title="$t('views.companies.inquiry_payments.index.payment_information')"
    size="xl"
  >
    <be-tabs
      v-model="modalActiveTab"
      :options="modalTabsData"
      class="d-print-none mb-3"
    />

    <div v-if="modalActiveTab === 'information'" class="row">
      <div class="col-12 col-lg-6">
        <h2>
          {{ $t("views.companies.inquiry_payments.index.inquiry_information") }}
        </h2>

        <be-table-simple>
          <tbody>
            <tr v-if="inquiry.inquiry_receiver?.name">
              <td class="font-weight-bold">
                {{ $t("activerecord.attributes.inquiry.receiver") }}
              </td>

              <td>{{ inquiry.inquiry_receiver.name }}</td>
            </tr>

            <tr v-if="inquiry.inquiry_receiver?.receiver_identifier">
              <td class="font-weight-bold">
                {{ $t("views.companies.inquiries.create.receiver_identifier") }}
              </td>

              <td>{{ inquiry.inquiry_receiver.receiver_identifier }}</td>
            </tr>

            <tr v-if="inquiry.title">
              <td class="font-weight-bold">
                {{ $t("activerecord.attributes.inquiry.title") }}
              </td>

              <td>{{ inquiry.title }}</td>
            </tr>

            <tr v-if="inquiry.notes">
              <td class="font-weight-bold">
                {{ $t("activerecord.attributes.inquiry.notes") }}
              </td>

              <td>{{ inquiry.notes }}</td>
            </tr>

            <tr v-if="inquiry.comments">
              <td class="font-weight-bold">
                {{ $t("activerecord.attributes.inquiry.comment") }}
              </td>

              <td>{{ inquiry.comments }}</td>
            </tr>

            <tr v-if="inquiry.granted_value">
              <td class="font-weight-bold">
                {{ $t("activerecord.attributes.inquiry.granted_value") }}
              </td>

              <td>
                <money-format
                  :currency="inquiry.currency"
                  :value="Number(inquiry.granted_value)"
                />
              </td>
            </tr>

            <tr>
              <td class="font-weight-bold">
                {{
                  $t("views.companies.inquiry_payments.index.more_information")
                }}
              </td>

              <td>
                <be-link
                  :href="url(`/inquiries/${inquiry.id}`)"
                  target="_blank"
                >
                  {{
                    $t("views.companies.inquiry_payments.index.go_to_inquiry")
                  }}
                </be-link>
              </td>
            </tr>
          </tbody>
        </be-table-simple>
      </div>

      <div class="col-12 col-lg-6">
        <h2>
          {{ $t("views.companies.inquiry_payments.index.payment_information") }}
        </h2>

        <be-table-simple class="w-100">
          <tbody>
            <tr>
              <td class="font-weight-bold">
                {{ $t("views.companies.inquiry_payments.index.payment_id") }}
              </td>

              <td>{{ payment.uuid }}</td>
            </tr>

            <tr>
              <td class="font-weight-bold">
                {{
                  $t(
                    `activerecord.attributes.inquiry.${payment.inquiry_receiver.transaction_type}`
                  )
                }}
              </td>

              <td>
                <be-link :href="externalAccountUrl">
                  {{ payment.inquiry_receiver.deposit_account_number }}
                </be-link>
              </td>
            </tr>

            <tr>
              <td class="font-weight-bold">
                {{ $t("views.companies.inquiry_payments.index.payment_date") }}
              </td>

              <td>
                {{ $d(new Date(payment.pay_at), "short") }}
              </td>
            </tr>

            <tr>
              <td class="font-weight-bold">
                {{ $t("views.companies.inquiry_payments.index.amount") }}
              </td>

              <td>
                <money-format
                  :currency="inquiry.currency"
                  :value="Number(payment.amount)"
                />
              </td>
            </tr>

            <tr>
              <td class="font-weight-bold">
                {{
                  $t(
                    `views.companies.inquiries.create.reference_type_${payment.reference_type}`
                  )
                }}
              </td>

              <td>{{ payment.reference }}</td>
            </tr>

            <tr>
              <td class="font-weight-bold">
                {{ $t("activerecord.attributes.inquiry.receiver") }}
              </td>

              <td>{{ payment.inquiry_receiver.name }}</td>
            </tr>

            <tr>
              <td class="font-weight-bold">
                {{ $t("views.companies.inquiries.create.receiver_identifier") }}
              </td>

              <td>{{ payment.inquiry_receiver.receiver_identifier }}</td>
            </tr>
          </tbody>
        </be-table-simple>

        <h3>
          {{ $t("views.companies.inquiry_payments.index.planned_amount") }}
        </h3>

        <p class="small">
          {{
            $t(
              "views.companies.inquiry_payments.index.planned_amount_description"
            )
          }}
        </p>

        <inquiry-payments-progress-bar
          :total-amount="inquiry.total_payments_planned"
          :granted-value="inquiry.granted_value"
          :currency="inquiry.currency"
        />
      </div>
    </div>

    <div v-if="modalActiveTab === 'document'">
      <h2>
        {{ $t("views.companies.inquiry_payments.index.approval_document") }}
      </h2>

      <be-file-viewer
        v-if="approvalDocument"
        :file-url="approvalDocument.preview.url"
        :file-type="approvalDocument.preview.content_type"
      />
    </div>

    <template #footer="{ close }">
      <be-button
        v-if="
          payment.state !== 'processed' &&
          payment.state !== 'completed' &&
          payment.state !== 'cancelled'
        "
        variant="danger"
        @click="handleCancel(inquiry.id, payment.id)"
      >
        {{
          $t(
            "views.companies.inquiry_payments.index.cancel_payment_in_pe_accounting"
          )
        }}
      </be-button>

      <be-button v-else @click="close">
        {{ $t("buttons.titles.close") }}
      </be-button>
    </template>
  </be-modal>

  <be-tabs v-model="activeTab" :options="tabsData" class="d-print-none">
    <template #content-right>
      <be-button v-be-modal.handle-payment-modal>
        {{ $t("views.companies.inquiry_payments.index.create_payment") }}
      </be-button>
    </template>
  </be-tabs>

  <div class="card">
    <div class="card-body">
      <!-- Initial -->
      <be-table
        v-if="activeTab === 'initial'"
        :fields="fields"
        :items="initialInquiryPayments"
        selectable
        @selected="onRowSelection('initial', $event)"
      >
        <template #transaction_type="{ item }">
          {{
            $t(
              `activerecord.attributes.inquiry.${item.inquiry_receiver.transaction_type}`
            )
          }}
        </template>

        <template #amount="{ item }">
          <money-format
            :currency="item.inquiry.currency"
            :value="Number(item.amount)"
          />
        </template>

        <template #pay_at="{ item }">
          {{ $d(new Date(item.pay_at), "short") }}
        </template>

        <template #status="{ item }">
          <i
            v-if="item.job_logs"
            v-be-tooltip="item.job_logs?.toString()"
            class="fas fa-hexagon-exclamation text-danger"
          />
        </template>

        <template #id="{ item }">
          <be-button size="sm" @click="openModal(item.inquiry.id, item)">
            {{ $t("buttons.titles.read_more") }}
          </be-button>

          <be-button size="sm" @click="openEditPaymentModal(item)">
            {{ $t("buttons.titles.edit") }}
          </be-button>

          <be-button size="sm" @click="removeInquiryPayment(item)">
            {{ $t("buttons.titles.remove") }}
          </be-button>
        </template>
      </be-table>

      <!-- Pending -->
      <be-table
        v-if="activeTab === 'pending'"
        :fields="fields"
        :items="createdAndPendingInquiryPayments"
        selectable
        @selected="onRowSelection('pending', $event)"
      >
        <template #transaction_type="{ item }">
          {{
            $t(
              `activerecord.attributes.inquiry.${item.inquiry_receiver.transaction_type}`
            )
          }}
        </template>

        <template #amount="{ item }">
          <money-format
            :currency="item.inquiry.currency"
            :value="Number(item.amount)"
          />
        </template>

        <template #pay_at="{ item }">
          {{ $d(new Date(item.pay_at), "short") }}
        </template>

        <template #status="{ item }">
          <i
            v-if="item.job_logs"
            v-be-tooltip="item.job_logs?.toString()"
            class="fas fa-hexagon-exclamation text-danger"
          />
        </template>

        <template #id="{ item }">
          <be-button size="sm" @click="openModal(item.inquiry.id, item)">
            {{ $t("buttons.titles.read_more") }}
          </be-button>

          <be-button size="sm" @click="openEditPaymentModal(item)">
            {{ $t("buttons.titles.edit") }}
          </be-button>

          <be-button size="sm" @click="removeInquiryPayment(item)">
            {{ $t("buttons.titles.remove") }}
          </be-button>
        </template>
      </be-table>

      <!-- Processed -->
      <be-table
        v-if="activeTab === 'processed'"
        :fields="fields"
        :items="processedInquiryPayments"
      >
        <template #transaction_type="{ item }">
          {{
            $t(
              `activerecord.attributes.inquiry.${item.inquiry_receiver.transaction_type}`
            )
          }}
        </template>

        <template #amount="{ item }">
          <money-format
            :currency="item.inquiry.currency"
            :value="Number(item.amount)"
          />
        </template>

        <template #pay_at="{ item }">
          {{ $d(new Date(item.pay_at), "short") }}
        </template>

        <template #id="{ item }">
          <be-button size="sm" @click="openModal(item.inquiry.id, item)">
            {{ $t("buttons.titles.read_more") }}
          </be-button>
        </template>
      </be-table>

      <!-- Failed -->
      <be-table
        v-if="activeTab === 'failed'"
        :fields="failedFields"
        :items="failedInquiryPayments"
        selectable
        @selected="onRowSelection('failed', $event)"
      >
        <template #transaction_type="{ item }">
          {{
            $t(
              `activerecord.attributes.inquiry.${item.inquiry_receiver.transaction_type}`
            )
          }}
        </template>

        <template #amount="{ item }">
          <money-format
            :currency="item.inquiry.currency"
            :value="Number(item.amount)"
          />
        </template>

        <template #status="{ item }">
          <i
            v-if="item.job_logs"
            v-be-tooltip="item.job_logs?.toString()"
            class="fas fa-hexagon-exclamation text-danger"
          />
        </template>

        <template #id="{ item }">
          <be-button size="sm" @click="openModal(item.inquiry.id, item)">
            {{ $t("buttons.titles.read_more") }}
          </be-button>

          <be-button size="sm" @click="openEditPaymentModal(item)">
            {{ $t("buttons.titles.edit") }}
          </be-button>

          <be-button size="sm" @click="removeInquiryPayment(item)">
            {{ $t("buttons.titles.remove") }}
          </be-button>
        </template>
      </be-table>

      <!-- Cancelled -->
      <be-table
        v-if="activeTab === 'cancelled'"
        :fields="failedFields"
        :items="cancelledInquiryPayments"
      >
        <template #transaction_type="{ item }">
          {{
            $t(
              `activerecord.attributes.inquiry.${item.inquiry_receiver.transaction_type}`
            )
          }}
        </template>

        <template #amount="{ item }">
          <money-format
            :currency="item.inquiry.currency"
            :value="Number(item.amount)"
          />
        </template>

        <template #status="{ item }">
          <i
            v-if="item.job_logs"
            v-be-tooltip="item.job_logs?.toString()"
            class="fas fa-hexagon-exclamation text-danger"
          />
        </template>

        <template #id="{ item }">
          <be-button size="sm" @click="openModal(item.inquiry.id, item)">
            {{ $t("buttons.titles.read_more") }}
          </be-button>

          <be-button size="sm" @click="openEditPaymentModal(item)">
            {{ $t("buttons.titles.edit") }}
          </be-button>

          <be-button size="sm" @click="removeInquiryPayment(item)">
            {{ $t("buttons.titles.remove") }}
          </be-button>
        </template>
      </be-table>

      <!-- Completed -->
      <be-table
        v-if="activeTab === 'completed'"
        :fields="completedFields"
        :items="completedInquiryPayments"
      >
        <template #transaction_type="{ item }">
          {{
            $t(
              `activerecord.attributes.inquiry.${item.inquiry_receiver.transaction_type}`
            )
          }}
        </template>

        <template #amount="{ item }">
          <money-format
            :currency="item.inquiry.currency"
            :value="Number(item.amount)"
          />
        </template>

        <template #id="{ item }">
          <be-button size="sm" @click="openModal(item.inquiry.id, item)">
            {{ $t("buttons.titles.read_more") }}
          </be-button>
        </template>
      </be-table>

      <!-- Buttons -->
      <div class="mt-4 d-flex justify-content-end">
        <!-- Send to PE Accounting :: Cancel Payments -->
        <be-button
          v-if="activeTab === 'pending' || activeTab === 'initial'"
          v-be-tooltip="{
            title: $t(
              'views.companies.inquiry_payments.index.select_payments_to_cancel'
            ),
            disabled: tooltipStatus('pending'),
          }"
          variant="danger"
          :disabled="!tooltipStatus('pending')"
          @click="handleMultipleCancel(selected.pending)"
        >
          {{
            $t(
              "views.companies.inquiry_payments.index.cancel_payments_in_pe_accounting"
            )
          }}
        </be-button>

        <be-button
          v-if="activeTab === 'failed'"
          v-be-tooltip="{
            title: $t(
              'views.companies.inquiry_payments.index.select_payments_to_cancel'
            ),
            disabled: tooltipStatus('failed'),
          }"
          variant="danger"
          :disabled="!tooltipStatus('failed')"
          @click="handleMultipleCancel(selected.pending)"
        >
          {{
            $t(
              "views.companies.inquiry_payments.index.cancel_payments_in_pe_accounting"
            )
          }}
        </be-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { EventBus } from "@/event-bus";
import MoneyFormat from "@/components/shared/MoneyFormat.vue";
import CreateInquiryMixin from "@/mixins/inquiries/createInquiry";
import FormNew from "@/components/inquiry_payments/form/FormNew.vue";
import InquiryPaymentsProgressBar from "@/components/inquiry_payments/components/ProgressBar.vue";

const TRANSACTION_TYPE_URL = {
  bankgiro: "https://www.bankgirot.se/sok-bankgironummer?bgnr=",
  plusgiro: "https://kontoutdrag.plusgirot.se/ku/html/sokkto.htm",
};

const PE_ACCOUNTING_URL =
  "https://my.accounting.pe/web/?redirect=/secure/foundation/pay&company=";

const PAYMENT_POLL_INTERVAL = 30000;

export default {
  components: {
    MoneyFormat,
    FormNew,
    InquiryPaymentsProgressBar,
  },

  mixins: [CreateInquiryMixin],

  props: {
    peAccountingCompanyId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      activeTab: "initial",
      modalActiveTab: "information",
      payment: {},

      selected: {
        pending: [],
        failed: [],
      },

      tooltip: {
        pending: false,
        failed: false,
      },

      fields: [
        { key: "status", label: "", sortable: false },
        {
          key: "inquiry_receiver.name",
          label: this.$t("activerecord.attributes.inquiry.receiver"),
          sortable: true,
        },
        {
          key: "inquiry_receiver.deposit_account_number",
          label: this.$t("views.companies.inquiry_payments.index.payment_to"),
          sortable: true,
        },
        {
          key: "transaction_type",
          label: this.$t("views.companies.inquiry_payments.index.payment_type"),
          sortable: true,
        },
        {
          key: "pay_at",
          label: this.$t("views.companies.inquiry_payments.index.payment_date"),
          sortable: true,
        },
        {
          key: "amount",
          label: this.$t("views.companies.inquiry_payments.index.amount"),
          sortable: true,
          class: "text-right",
        },
        { key: "id", label: "", sortable: false, class: "text-right" },
      ],

      // Add date for failed_at
      failedFields: [
        { key: "status", label: "", sortable: false },
        {
          key: "inquiry_receiver.name",
          label: this.$t("activerecord.attributes.inquiry.receiver"),
          sortable: true,
        },
        {
          key: "inquiry_receiver.deposit_account_number",
          label: this.$t("views.companies.inquiry_payments.index.payment_to"),
          sortable: true,
        },
        {
          key: "transaction_type",
          label: this.$t("views.companies.inquiry_payments.index.payment_type"),
          sortable: true,
        },
        {
          key: "amount",
          label: this.$t("views.companies.inquiry_payments.index.amount"),
          sortable: true,
          class: "text-right",
        },
        { key: "id", label: "", sortable: false, class: "text-right" },
      ],

      // Add date for completed_at
      completedFields: [
        { key: "status", label: "", sortable: false },
        {
          key: "inquiry_receiver.name",
          label: this.$t("activerecord.attributes.inquiry.receiver"),
          sortable: true,
        },
        {
          key: "inquiry_receiver.deposit_account_number",
          label: this.$t("views.companies.inquiry_payments.index.payment_to"),
          sortable: true,
        },
        {
          key: "transaction_type",
          label: this.$t("views.companies.inquiry_payments.index.payment_type"),
          sortable: true,
        },
        {
          key: "amount",
          label: this.$t("views.companies.inquiry_payments.index.amount"),
          sortable: true,
          class: "text-right",
        },
        { key: "id", label: "", sortable: false, class: "text-right" },
      ],
    };
  },

  computed: {
    ...mapGetters({
      getInquiry: "inquiries/getInquiry",
      getInquiries: "inquiries/getInquiries",
      getInquiryPayments: "inquiries/getInquiryPayments",
      loadedInquiries: "inquiries/hasLoadedInquiries",
      loadedInquiryPayments: "inquiries/hasLoadedInquiryPayments",
    }),

    handlePaymentModalTitle() {
      if (this.form.id) {
        return this.$t("views.companies.inquiry_payments.index.update_payment");
      }

      return this.$t("views.companies.inquiry_payments.index.create_payment");
    },

    approvalDocument() {
      if (this.inquiry.approval_meeting_document) {
        // Return meeting approval document
        return this.inquiry.approval_meeting_document;
      }

      if (this.inquiry.documents) {
        // Return form uploaded approval document
        return this.inquiry.documents[0];
      }

      return null;
    },

    modalTabsData() {
      return [
        {
          label: this.$t("views.companies.inquiry_payments.index.information"),
          value: "information",
        },
        {
          label: this.$t(
            "views.companies.inquiry_payments.index.approval_document"
          ),

          value: "document",
        },
      ];
    },

    tabsData() {
      return [
        {
          label: this.$t(
            "views.companies.inquiry_payments.index.initial_payments"
          ),

          value: "initial",
          count: this.initialInquiryPayments.length,
        },
        {
          label: this.$t(
            "views.companies.inquiry_payments.index.pending_payments"
          ),

          value: "pending",
          count: this.createdAndPendingInquiryPayments.length,
        },
        {
          label: this.$t(
            "views.companies.inquiry_payments.index.processed_payments"
          ),

          value: "processed",
          count: this.processedInquiryPayments.length,
        },
        {
          label: this.$t(
            "views.companies.inquiry_payments.index.cancelled_tab"
          ),

          value: "cancelled",
          count: this.cancelledInquiryPayments.length,
        },
        {
          label: this.$t(
            "views.companies.inquiry_payments.index.failed_payments"
          ),

          value: "failed",
          count: this.failedInquiryPayments.length,
        },
        {
          label: this.$t(
            "views.companies.inquiry_payments.index.completed_payments"
          ),

          value: "completed",
          count: this.completedInquiryPayments.length,
        },
      ];
    },

    initialInquiryPayments() {
      return this.getInquiryPayments({
        paymentState: ["initial"],
      });
    },

    createdInquiryPaymentsCount() {
      return this.getInquiryPayments({
        paymentState: ["created"],
      }).length;
    },

    createdAndPendingInquiryPayments() {
      return this.getInquiryPayments({
        paymentState: ["created", "pending"],
      });
    },

    processedInquiryPayments() {
      return this.getInquiryPayments({
        paymentState: ["processed"],
      });
    },

    cancelledInquiryPayments() {
      return this.getInquiryPayments({
        paymentState: ["cancelled"],
      });
    },

    failedInquiryPayments() {
      return this.getInquiryPayments({
        paymentState: ["failed"],
      });
    },

    completedInquiryPayments() {
      return this.getInquiryPayments({
        paymentState: ["completed"],
      });
    },

    externalAccountUrl() {
      if (!this.payment.transactionType || !this.payment.account) {
        return;
      }

      if (this.payment.transactionType === "Bankgiro") {
        return (
          TRANSACTION_TYPE_URL["bankgiro"] + encodeURI(this.payment.account)
        );
      } else if (this.payment.transactionType === "Plusgiro") {
        return TRANSACTION_TYPE_URL["plusgiro"];
      }

      return "";
    },

    payableInquiries() {
      const payableInquiries = this.getInquiries({
        status: ["approved"],
        source: "form",
        remainingToPay: true,
        syncedToPe: true,
        requisition: false,
      });

      return payableInquiries.map((inquiry) => {
        const title = inquiry.title || inquiry.external_id;

        return {
          value: inquiry.id,
          text: `#${inquiry.id} - ${title} (${inquiry.inquiry_receiver.name}) - ${inquiry.granted_value} ${inquiry.currency}`,
        };
      });
    },

    inquiry() {
      return this.getInquiry;
    },

    peAccountingUrl() {
      if (!this.peAccountingCompanyId) {
        return;
      }

      return PE_ACCOUNTING_URL + this.peAccountingCompanyId;
    },
  },

  watch: {
    activeTab(newTab, oldTab) {
      this.selected[oldTab] = [];
    },

    async "form.inquiry.inquiry_id"(newId, oldId) {
      if (newId !== oldId) {
        await this.loadInquiry(newId);

        // Prepoulate payment_plan.inquiry_receiver with data from inquiry_receiver to make it easier for end-user
        if (this.form.inquiry.inquiry_id) {
          if (!this.form.payment_plan[0].inquiry_receiver?.name) {
            this.form.payment_plan[0].inquiry_receiver.name =
              this.inquiry.inquiry_receiver.name;
          }
          if (
            !this.form.payment_plan[0].inquiry_receiver?.receiver_identifier
          ) {
            this.form.payment_plan[0].inquiry_receiver.receiver_identifier =
              this.inquiry.inquiry_receiver.receiver_identifier;
          }
        } else {
          this.form.payment_plan[0].inquiry_receiver.name = "";
          this.form.payment_plan[0].inquiry_receiver.receiver_identifier = "";
        }
      }
    },
  },

  mounted() {
    if (!this.loadedInquiries && this.platformEnabled("inquiries")) {
      this.loadInquiries();
    }
    if (!this.loadedInquiryPayments && this.platformEnabled("inquiries")) {
      this.loadInquiryPayments();

      setInterval(() => {
        this.loadInquiryPayments();
      }, PAYMENT_POLL_INTERVAL);
    }
  },

  methods: {
    ...mapActions({
      loadInquiries: "inquiries/loadInquiries",
      loadInquiryPayments: "inquiries/loadInquiryPayments",
      loadInquiry: "inquiries/loadInquiry",
    }),

    ...mapMutations("inquiries", ["resetNewInquiryForm"]),

    onRowSelection(tab, items) {
      this.selected[tab] = items;
    },

    tooltipStatus(tab) {
      if (this.selected[tab].length === 0) {
        return false;
      }

      return true;
    },

    async openEditPaymentModal(item) {
      // This can be refactored when we remove array from payment_plan
      const paymentForm = {
        id: item.id,

        inquiry: {
          inquiry_id: item.inquiry.id,
        },

        payment_plan: [
          {
            amount: item.amount,
            pay_at: item.pay_at,
            reference: item.reference,
            reference_type: item.reference_type,

            inquiry_receiver: {
              name: item.inquiry_receiver.name,
              receiver_identifier: item.inquiry_receiver.receiver_identifier,
              transaction_type: item.inquiry_receiver.transaction_type,
              bankgiro_number: item.inquiry_receiver.bankgiro_number,
              plusgiro_number: item.inquiry_receiver.plusgiro_number,
              iban: item.inquiry_receiver.iban,
              bic: item.inquiry_receiver.bic,
              bank_account_number: item.inquiry_receiver.bank_account_number,
            },
          },
        ],
      };

      this.initInquiryForm(paymentForm);
      await this.loadInquiry(item.inquiry.id);

      this.$beModal.show("handle-payment-modal");
    },

    async handleUpdatePayment() {
      try {
        const { inquiry, id } = this.form;

        await axios.patch(
          this.url(`inquiries/${inquiry.inquiry_id}/inquiry_payments/${id}`),
          this.form
        );

        EventBus.emit("new-notification", {
          message: this.$t(
            "views.companies.inquiry_payments.index.updated_payment"
          ),
        });

        this.$beModal.hide("handle-payment-modal");
        this.loadInquiries();
        this.loadInquiryPayments();
      } catch (error) {
        if (error.response.status === 422) {
          // We currently only support the first payment plan.
          this.form.payment_plan[0].errors = error.response.data.errors;
        } else {
          this.handleError(error);
        }
      }
    },

    async removeInquiryPayment(item) {
      try {
        await axios.delete(
          this.url(`inquiries/${item.inquiry.id}/inquiry_payments/${item.id}`)
        );

        EventBus.emit("new-notification", {
          message: this.$t(
            "views.companies.inquiry_payments.index.payment_removed"
          ),
        });
      } catch (error) {
        this.handleError(error);
        EventBus.emit("new-notification", {
          message: this.$t(
            "views.companies.inquiry_payments.index.payment_removed_error"
          ),

          status: "danger",
        });
      }

      this.loadInquiryPayments();
    },

    async openModal(inquiry_id, item) {
      this.payment = item;
      await this.loadInquiry(inquiry_id);
      this.$beModal.show("inquiry-payment-modal");
    },

    async handleCancel(inquiryId, paymentId) {
      try {
        await axios.post(
          this.url(
            `inquiries/${inquiryId}/inquiry_payments/${paymentId}/cancellations`
          )
        );

        EventBus.emit("new-notification", {
          message: this.$t(
            "views.companies.inquiry_payments.index.cancelled_payment"
          ),
        });

        this.$beModal.hide("inquiry-payment-modal");

        this.loadInquiries();
        this.loadInquiryPayments();
      } catch (error) {
        this.handleError(error);
      }
    },

    async handleMultipleCancel(selected) {
      try {
        await Promise.all(
          selected.map((item) => {
            const { id, inquiry } = item;
            return axios.post(
              this.url(
                `/inquiries/${inquiry.id}/inquiry_payments/${id}/cancellations`
              )
            );
          })
        );

        EventBus.emit("new-notification", {
          message: this.$t(
            "views.companies.inquiry_payments.index.cancelled_payments"
          ),
        });

        this.loadInquiries();
        this.loadInquiryPayments();
      } catch (error) {
        this.handleError(error);
      }
    },

    async handleMakePayment() {
      try {
        const inquiryId = this.form.inquiry.inquiry_id;

        await axios.post(
          this.url(`/inquiries/${inquiryId}/inquiry_payments`),
          this.form
        );

        EventBus.emit("new-notification", {
          message: this.$t(
            "views.companies.inquiry_payments.index.created_payment_and_sent_to_pe_accounting"
          ),
        });

        this.$beModal.hide("handle-payment-modal");
        this.resetNewInquiryForm();
        this.loadInquiries();
        this.loadInquiryPayments();
      } catch (error) {
        if (error.response.status === 422) {
          // We currently only support the first payment plan.
          this.form.payment_plan[0].errors = error.response.data.errors;
        } else {
          this.handleError(error);
        }
      }
    },
  },
};
</script>
