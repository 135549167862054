<template>
  <be-list-group flush>
    <be-list-group-item class="p-4">
      <task-container
        action-key="report_and_analysis_get_started_visited"
        section-type="user_info_fortnox"
        :onboarding-object="$currentUser"
        :title="
          $t(
            'components.onboarding.introduction.user_section_fortnox.get_started_title'
          )
        "
      >
        <template #text>
          <div class="mb-2">
            {{
              $t(
                "components.onboarding.introduction.user_section_fortnox.get_started_description"
              )
            }}
          </div>

          <div class="mb-3">
            <i18n-t
              keypath="components.onboarding.introduction.support_section_fortnox.faq_description_w_link"
              tag="span"
            >
              <template #link>
                <be-link
                  href="https://support.fortnox.se/produkthjalp/rapport-analys/faq-testperiod-rapport-analys-byra"
                >
                  {{
                    $t(
                      "components.onboarding.introduction.support_section_fortnox.faq_page"
                    )
                  }}
                </be-link>
              </template>
            </i18n-t>
          </div>

          <be-alert>
            {{
              $t(
                "components.onboarding.introduction.user_section.report_analysis_support_description"
              )
            }}
          </be-alert>
        </template>

        <template #link>
          <be-link
            href="https://www.fortnox.se/utbildning/572599"
            class="d-none d-md-inline-block"
            @click="$emit('report-and-analysis-get-started-visited')"
          >
            {{
              $t(
                "components.onboarding.introduction.user_section_fortnox.get_started_link"
              )
            }}
          </be-link>
        </template>
      </task-container>
    </be-list-group-item>
  </be-list-group>
</template>

<script>
import TaskContainer from "@/components/onboarding/TaskContainer.vue";

export default {
  components: {
    TaskContainer,
  },

  emits: ["report-and-analysis-get-started-visited"],
};
</script>
